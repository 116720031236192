
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
    // @ts-ignore: Vuetify error
    import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
    import { CKEditorVideoConfig } from '@/modules/core/ckeditor/ckeditor-video'
    import {Label} from "@/modules/label/models/Label";

    @Component<LabelForm>({
        watch: {
            labelReference: {
                handler: function (value: Object, oldValue: Object) {
                    this.setInitialValues();
                },
                deep: true,
                immediate: true
            }
        }
    })
    export default class LabelForm extends Vue {
        @Prop({default: null}) labelReference: Label | undefined

        label: Label | null = null

        editor = ClassicEditor
        editorConfig = {
            plugins: CKEditorVideoConfig.plugins,
            toolbar: CKEditorVideoConfig.toolbar,
            placeholder: 'Beschreibung...',
        }

        valid = true
        panels: number = 1
        openedPanels = []

        openAllPanels () {
            if (this.panels !== null) {
                this.openedPanels = this.getPanelArr(this.panels);
            }
        }

        closeAllPanels () {
            this.openedPanels = [];
        }

        getPanelArr(panelsInt: number) : any {
            let panelArr: any = [];

            while (panelsInt !== 0) {
                panelArr[panelsInt-1] = panelsInt-1;
                panelsInt--;
            }

            return panelArr;
        }

        async created() {
            this.setInitialValues();
            if (this.panels !== null) {
                this.openedPanels = this.getPanelArr(this.panels);
            }
        }

        setInitialValues() {
            if (this.label !== null) {
                if (this.labelReference !== null && this.labelReference !== undefined) {
                    this.label.name = this.labelReference.name
                    this.label.description = this.labelReference.description
                    this.label.draft_item_id = this.labelReference.draft_item_id
                    this.label.section = this.labelReference.section
                }
            } else {
                this.label = {
                    name: '',
                    description: '',
                    draft_item_id: 0
                }
            }
        }

        validate() {
            // @ts-ignore: Vuetify error
            if (this.$refs.form.validate()) {
                this.$emit('send', this.label);
            }
        }

        reset() {
            if (this.labelReference !== null && this.labelReference !== undefined) {
                this.setInitialValues();
            } else {
                // @ts-ignore: Vuetify error
                this.$refs.form.reset();
            }
        }
    }
