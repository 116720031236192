import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import {FileDialogButtonView} from '@ckeditor/ckeditor5-upload/src/index';
import mediaIcon from '@ckeditor/ckeditor5-media-embed/theme/icons/media.svg';
import Vue from 'vue'
import {MoodleApi} from "@/modules/core/webservice/moodle-api";

export default class InsertVideo extends Plugin {
    init() {
        const editor = this.editor
        const schema = editor.model.schema
        const registry = this.registry

        // Configure the schema.
        schema.register('video', {
            isObject: true,
            isBlock: true,
            allowWhere: '$block',
            allowAttributes: ['controls', 'videoUrl'],
        })

        // Model -> View (element)
        editor.conversion.for('downcast').elementToElement({
            model: 'video',
            view: (modelElement: any, {writer}: any) => {
                let tokenParam = ''

                const token = Vue.prototype.$customStore.getters['login/GET_TOKEN']
                if (token) {
                    tokenParam = '?token=' + token
                }

                const videoUrl = modelElement.getAttribute('videoUrl') + tokenParam

                return  this.createVideoElement(writer, videoUrl)
            }
        })

        // View -> Model (element)
        editor.conversion.for('upcast')
            // Upcast semantic media.
            .elementToElement({
                view: (element: any) => ['video'].includes(element.name) && element.getAttribute('controls') ? {name: true} : null,
                model: (viewVideo: any, {writer}: any) => {
                    let videoUrl = ''

                    const child = viewVideo.getChild(0)

                    if (child.name === 'source' && child.hasAttribute('src')) {
                        videoUrl = child.getAttribute('src')
                    }

                    return writer.createElement('video', {
                        controls: true,
                        videoUrl: videoUrl
                    })
                }
            })
            // Upcast non-semantic media.
            .elementToElement({
                view: {
                    name: 'video',
                    attributes: {
                        'controls': true
                    }
                },
                model: (viewVideo: any, {writer}: any) => {
                    let videoUrl = ''

                    const child = viewVideo.getChild(0)

                    if (child.name === 'source' && child.hasAttribute('src')) {
                        videoUrl = child.getAttribute('src')
                    }

                    return writer.createElement('video', {
                        controls: true,
                        videoUrl: videoUrl
                    })
                }
            })

        const componentCreator = (locale: any) => {
            const view = new FileDialogButtonView(locale)
            const videoTypes = ['mp4']
            const imageTypesRegExp = this.createVideoTypeRegExp(videoTypes)

            view.set({
                acceptedType: videoTypes.map((type: any) => `video/${ type }`).join(','),
                allowMultipleFiles: false
            })

            view.buttonView.set({
                label: 'Video einfügen',
                icon: mediaIcon,
                tooltip: true
            })

            view.on('done', (evt: any, files: Iterable<unknown> | ArrayLike<unknown>) => {
                // @ts-ignore
                const videosToInsert = Array.from(files).filter(file => imageTypesRegExp.test( file.type ))

                if (videosToInsert.length) {
                    videosToInsert.forEach(async (video) => {
                        // Upload file to Moodle
                        let itemId = await this.uploadFile(video as File)

                        if (itemId === false) {
                            console.log('Error on file upload with file name: ' + (video as File).name)
                        }

                        editor.model.change( (writer: any) => {
                            const userContextId = Vue.prototype.$customStore.getters['login/GET_USER_CONTEXT_ID']
                            const draftItemId = Vue.prototype.$customStore.getters['core/GET_LABEL_VIDEO_DRAFT_ITEM_ID']

                            let videoUrl = process.env.VUE_APP_MOODLE_API_BASE_URL
                                + '/local/lumaconi/draftfile.php/'
                                + userContextId
                                + '/user/draft/'
                                + draftItemId
                                + '/'
                                + (video as File).name

                            const videoElement = writer.createElement('video', {
                                // @ts-ignore
                                controls: true,
                                videoUrl: videoUrl
                            })

                            // Insert the image in the current selection location.
                            // @ts-ignore
                            editor.model.insertContent(videoElement, editor.model.document.selection)
                        });
                    })
                }
            })

            return view
        }

        editor.ui.componentFactory.add('insertVideo', componentCreator)
    }

    createVideoElement(writer: any, url: string) {
        const containerElement = writer.createContainerElement('p')

        const video = writer.createContainerElement('video', {controls: true})

        const source = writer.createRawElement('source', {src: url}, (domElement: any) => {
            domElement.innerHTML = url
            return domElement
        });

        writer.insert(writer.createPositionAt(video, 0), source)
        writer.insert(writer.createPositionAt(containerElement, 0), video)

        return containerElement
    }

    createVideoTypeRegExp(types: any[] ) {
        // Sanitize the MIME type name which may include: "+", "-" or ".".
        const regExpSafeNames = types.map(type => type.replace('+', '\\+'))
        return new RegExp(`^video\\/(${ regExpSafeNames.join( '|' ) })$`)
    }

    async uploadFile(file: File) {
        if(file) {
            let formData = new FormData()
            formData.append('file', file)

            const draftItemId = Vue.prototype.$customStore.getters['core/GET_LABEL_VIDEO_DRAFT_ITEM_ID']

            let response = await MoodleApi.upload(formData, draftItemId)

            if(Array.isArray(response) && response.length > 0 && response[0].hasOwnProperty('itemid')) {
                if (draftItemId === 0) {
                    Vue.prototype.$customStore.dispatch('core/ADD_LABEL_VIDEO_DRAFT_ITEM_ID', response[0].itemid)
                }
                return response[0].itemid
            }

            return false
        }
    }

    /**
     * @inheritDoc
     */
    static get pluginName() {
        return 'InsertVideo'
    }
}